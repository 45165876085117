import React from "react";
import BookTable from "../components/BookTable";

export default class BookSaleReturnPage extends React.PureComponent<RouterPropsType> {
  render() {
    return <BookTable
      type="saleReturn"
      history={this.props.history}
    />
  }
}